import React from "react";
import CercleIcon from "./cercle-icon";
import "./loader.css";

interface LoaderProps {
    className?: string;
}
export default function Loader({ className }: LoaderProps) {
    return (
        <div className="relative flex justify-center items-center min-h-screen">
            <img
                src="/dist/Kalypay_K.png"
                className={`absolute ${className}`}
                alt="Logo"
            />
            <CercleIcon className={`rotate-svg ${className}`} />
        </div>
    );
}

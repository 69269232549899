import loadable from "@loadable/component";
import AnonymousRouteProvider from "../../Routes/RouteProvider/AnonymousRouteProvider";
import RoutesNames from "../../Services/RoutesNames";
import KalypayBase from "../layout/Kalypay/KalypayBase";
import PaymentBase from "../pages/Payment/PaymentBase";
import Home from "../pages/Home";

const SupportLayout = loadable(() => import("../pages/(support-pages)/layout"));
const Branding = loadable(() => import("../pages/branding/branding"));
const MentionLegale = loadable(
    () => import("../pages/informations/MentionLegale"),
);
const Confidentialite = loadable(
    () => import("../pages/informations/Confidentialite"),
);
const KycAml = loadable(() => import("../pages/informations/KycAml"));
const CGV = loadable(() => import("../pages/informations/CGV"));
const OurFee = loadable(() => import("../pages/informations/OurFee"));

const Register = loadable(() => import("../pages/Register/Register"));
const Login = loadable(() => import("../pages/Login/Login"));

const Error = loadable(() => import("../pages/Error/Error"));
const ForgotPassword = loadable(
    () => import("../pages/Password/ForgotPassword/ForgotPassword"),
);
const NewPassword = loadable(
    () => import("../pages/Password/NewPassword/NewPassword"),
);

const Maintenance = loadable(() => import("../pages/Maintenace/Maintenance"));

const KalypayPayment = loadable(
    () => import("../pages/Payment/KalypayPayment"),
);

const PaymentError = loadable(() => import("../pages/Payment/PaymentError"));

const PaymentSuccess = loadable(
    () => import("../pages/Payment/PaymentSuccess"),
);
const VerifyEmail = loadable(() => import("../pages/verifyEmail/VerifyEmail"));
import Discover from "../pages/discover/discover";
import About from "../pages/about";
import IbanAndCard from "../pages/ibanAndCard";
import LegalWarning from "../pages/informations/LegalWarning";
import Invest from "../pages/invest/Invest";

const LandingRoutes = [
    {
        element: <KalypayBase />,
        children: [
            {
                path: RoutesNames.Home,
                element: <Home />,
            },
            {
                path: RoutesNames.Invest,
                element: <Invest />,
            },
            {
                path: RoutesNames.About,
                element: <About />,
            },
            {
                path: RoutesNames.IbanAndCard,
                element: <IbanAndCard />,
            },
            {
                path: RoutesNames.Discover,
                element: <Discover />,
            },
            {
                path: RoutesNames.Branding,
                element: <Branding />,
            },
            {
                path: RoutesNames.MentionLegale,
                element: <MentionLegale />,
            },
            {
                path: RoutesNames.CGV,
                element: <CGV />,
            },
            {
                path: RoutesNames.Confidentialite,
                element: <Confidentialite />,
            },
            {
                path: RoutesNames.LegalWarning,
                element: <LegalWarning />,
            },
            {
                path: RoutesNames.OurFee,
                element: <OurFee />,
            },
            {
                path: RoutesNames.KycAml,
                element: <KycAml />,
            },
        ],
    },
    {
        path: "support/:group/:item",
        element: <SupportLayout />,
    },

    {
        element: <PaymentBase />,
        children: [
            {
                path: RoutesNames.PaymentKp,
                element: <KalypayPayment />,
            },
        ],
    },
    {
        path: "/payment/error",
        element: <PaymentError />,
    },
    {
        path: "/payment/cancel",
        element: <PaymentError />,
    },
    {
        path: "/payment/success",
        element: <PaymentSuccess />,
    },
    {
        element: <AnonymousRouteProvider />,
        children: [
            {
                path: RoutesNames.Login,
                element: <Login />,
            },
            {
                path: RoutesNames.SilentLogin,
                element: <Login />,
            },
            {
                path: `${RoutesNames.Register}/:parrain?`,
                element: <Register />,
            },
            {
                path: `${RoutesNames.SilentRegister}/:parrain?`,
                element: <Register />,
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />,
            },
            {
                path: "reset-password",
                element: <NewPassword />,
            },
            {
                path: "verify-email",
                element: <VerifyEmail />,
            },
            {
                path: "unlock-account/:uid/:code",
                element: <NewPassword />,
            },
        ],
    },
    {
        path: "*",
        element: <Error />,
    },
    {
        path: "maintenance",
        element: <Maintenance />,
    },
];

export default LandingRoutes;

import { Menu, Transition } from "@headlessui/react";
import { useAppDispatch } from "../../../globalState/hook";
import useLanguage from "../../../Services/Hooks/useLanguage";
import translator from "../../../Components/Extra/Translation/Translate";
import {
    ChangeAppLanguage,
    Language,
} from "../../../globalState/reducers/Language.reducer";
import UtilsService from "../../../Services/UtilsService";
import { ChevronDown, Globe } from "lucide-react";
import UserService from "../../../Services/User/UserService";
import { useState } from "react";
import useAuthentication from "../../../Services/Authentication/useAuthentication";

export default function DropdownLanguage({
    align,
}: {
    align?: "left" | "right" | "center";
}) {
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuthentication();
    const { language } = useLanguage();
    const { translate } = translator();

    const updateLanguageClick = async (lang: Language) => {
        dispatch(ChangeAppLanguage({ language: lang }));
        if (isAuthenticated) {
            UserService.updateLanguage(lang).catch((err) => {
                console.error(
                    "Erreur lors de la mise à jour de la langue :",
                    err,
                );
            });
        }
    };

    const languageUrl = (lang: Language) => {
        const images: { [key in Language]: string } = {
            [Language.EN]: "/dist/image/language/us.svg",
            [Language.ES]: "/dist/image/language/es.svg",
            [Language.DE]: "/dist/image/language/de.svg",
            [Language.TR]: "/dist/image/language/tr.svg",
            [Language.ZH]: "/dist/image/language/zh.svg",
            [Language.PT_BRA]: "/dist/image/language/br.svg",
            [Language.FR]: "/dist/image/language/fr.svg",
        };
        return images[lang] || images[Language.FR];
    };

    const LANGUAGES = [
        {
            code: "EN",
            name: translate("LANGAGE", "english"),
            value: Language.EN,
        },
        {
            code: "FR",
            name: translate("LANGAGE", "french"),
            value: Language.FR,
        },
        {
            code: "ES",
            name: translate("LANGAGE", "spanish"),
            value: Language.ES,
        },
        {
            code: "DE",
            name: translate("LANGAGE", "german"),
            value: Language.DE,
        },
        {
            code: "TR",
            name: translate("LANGAGE", "turkish"),
            value: Language.TR,
        },
        {
            code: "ZH",
            name: translate("LANGAGE", "chinese"),
            value: Language.ZH,
        },
        {
            code: "PT-BR",
            name: translate("LANGAGE", "portuguese"),
            value: Language.PT_BRA,
        },
    ];

    const currentLang = LANGUAGES.find((lang) => lang.value === language);

    return (
        <Menu as="div" className="flex relative">
            <Menu.Button className="flex flex-row gap-1 text-white items-center justify-center">
                <Globe size={25} />
                <span className="text-lg font-semibold">
                    {currentLang?.code ?? "FR"}
                </span>
                <ChevronDown size={15} />
            </Menu.Button>

            <Transition
                className={`origin-top-right z-10 absolute top-full -mr-48 xs:!mr-[-128px] bg-slate-800 py-1.5 rounded shadow-lg overflow-hidden mt-3 ${
                    align === "right" ? "right-0" : "left-5 ml-[-90px]"
                }`}
                enter="transition ease-out duration-200 transform"
                enterFrom="opacity-0 -translate-y-2"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Menu.Items as="ul" className="focus:outline-none">
                    {LANGUAGES.map((lang) => (
                        <Menu.Item
                            as="li"
                            key={lang.code}
                            className="border-b border-slate-400/50 text-slate-300 last:border-0"
                        >
                            <button
                                className="flex items-center py-2 px-4 w-full hover:bg-slate-700"
                                onClick={() => updateLanguageClick(lang.value)}
                            >
                                <img
                                    alt={lang.name}
                                    src={UtilsService.getPulicImage(
                                        languageUrl(lang.value),
                                    )}
                                    className="w-6 h-6 mr-2"
                                />
                                <span className="text-base font-medium">
                                    {lang.name}
                                </span>
                            </button>
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

export default function EmailIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M3 4h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1m16.031 1.914l-6.875 5.5a.25.25 0 0 1-.312 0l-6.875-5.5l-.938 1.172l6.876 5.5a1.75 1.75 0 0 0 2.186 0l6.876-5.5z"
            />
        </svg>
    );
}

import { NavLink, useNavigate } from "react-router-dom";
import UtilsService from "../../../Services/UtilsService";
import KpButton from "../../Components/Inputs/KpButton";
import translator from "../../../Components/Extra/Translation/Translate";
import { useAssets } from "../../Provider/AssetsProvider";
import { UserCryptoWallet } from "../../../Models/UserWallet";

interface WalletRowProps {
    wallet: UserCryptoWallet;
    index: number;
    handleActiveAsset?: (value: string) => void;
    activeAsset?: string;
}

export const WalletRow = ({
    wallet,
    index,
    handleActiveAsset,
    activeAsset,
}: WalletRowProps) => {
    const { translate } = translator();
    const navigate = useNavigate();
    const { currentAsset, setCurrentAsset, convertAmountWithCurrency } =
        useAssets();
    const showDetails: boolean = wallet.abbreviation === activeAsset;
    const solde = wallet.wallet.solde;
    const valid_solde = solde > 0;

    const links = {
        deposit: {
            link:
                wallet.deposit_status == true
                    ? `/assets/deposit?asset=${wallet.abbreviation}`
                    : "",
            class: "btn btn-perfect md-w-13 !sm-w-auto",
        },
        buy: {
            link: wallet.buy_enabled
                ? `/assets/buy?asset=${wallet.abbreviation}`
                : "#buy",
            class: "btn btn-perfect md-w-13 !sm-w-auto",
        },
        sell: {
            link: wallet.sell_enabled
                ? `/assets/sell?asset=${wallet.abbreviation}`
                : "#buy",
            class: "btn btn-perfect md-w-13 !sm-w-auto",
        },
        exchange: {
            link:
                wallet.swap_status == true
                    ? `/assets/exchange?asset=${wallet.abbreviation}`
                    : "",
            class: "btn btn-perfect md-w-13 !sm-w-auto",
        },
        transfert: {
            link: `/assets/transfert/intern?asset=${wallet.abbreviation}`,
            class: "btn btn-perfect md-w-13 !sm-w-auto",
        },
    };

    const price_change_percentage_24 = wallet?.price_change_percentage_24 ?? 0;
    // const is_deposit_kalypay = wallet?.is_deposit_kalypay ?? false;
    const after_comma = wallet.type === "fiats" ? 2 : 8;
    const usd_price = wallet.wallet.solde * wallet.current_price;

    return (
        <>
            <tr
                // className="data-tbody border-t border-slate-400/60 hover:bg-gray-100 text-sm"
                className={
                    showDetails
                        ? "border-t border-slate-400/60 text-sm !cursor-default"
                        : "border-t border-slate-400/60 text-sm !cursor-default"
                }
                key={index}
                role="button"
                onClick={() => {
                    if (handleActiveAsset) {
                        handleActiveAsset(
                            !!showDetails ? "" : wallet.abbreviation,
                        );
                    }
                }}
            >
                <td
                    // data-label={translate("Wallet_Online", "Name")}
                    className="md-w-25 p-2 tb-mobiles"
                >
                    <div className="flex items-center">
                        <div className="sm:h-8 sm:w-8 xs:h-8 xs-8 md:h-10 md:w-10 lg:h-10 lg:w-10 xl:h-10 xl:w-10 2xl:h-10 2xl:w-10">
                            <img
                                src={UtilsService.getPhotoUrl(wallet.logo)}
                                alt=""
                                className="h-full"
                            />
                        </div>
                        <div className="block pl-2">
                            <div className="text-medium sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                                {wallet.name}
                            </div>
                            <div className="text-slate-400/60 sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm flex">
                                <div className="sm-block text-right">
                                    {convertAmountWithCurrency(
                                        wallet.current_price,
                                    )}
                                </div>
                                <div className="sm-hidden ">
                                    {wallet.abbreviation}
                                </div>
                                <div className="sm-block ml-2">
                                    {price_change_percentage_24 !== 0 ? (
                                        price_change_percentage_24 < 0 ? (
                                            <div className="text-red-500 sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm flex items-center">
                                                <div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        fill="currentColor"
                                                        className="bi bi-caret-down-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        {" "}
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />{" "}
                                                    </svg>
                                                </div>
                                                <div className="sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                                                    {UtilsService.formatDecimal(
                                                        UtilsService.getAbsoluteValue(
                                                            price_change_percentage_24,
                                                        ),
                                                        2,
                                                    )}{" "}
                                                    %
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="text-green-600 sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm flex items-center">
                                                <div className="rotate-180">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        fill="currentColor"
                                                        className="bi bi-caret-down-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        {" "}
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />{" "}
                                                    </svg>
                                                </div>
                                                <div className="sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                                                    {UtilsService.formatDecimal(
                                                        UtilsService.getAbsoluteValue(
                                                            price_change_percentage_24,
                                                        ),
                                                        2,
                                                    )}{" "}
                                                    %
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        " "
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td
                    // data-label={translate("Wallet_Online", "Solde")}
                    className="md-w-25 p-2 tb-mobiles"
                >
                    <div className="block md-right-table">
                        <div className="text-medium sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm md:text-left lg:text-left xl:text-left 2xl:text-left sm:text-right">
                            {UtilsService.formatAmount(
                                wallet.wallet.solde,
                                after_comma,
                            )}{" "}
                            <span className="sm-hidden">
                                {wallet.abbreviation}
                            </span>
                        </div>
                        <div className="text-slate-400 sm:text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-sm md-right-table">
                            {convertAmountWithCurrency(usd_price)}
                        </div>
                    </div>
                </td>
                <td
                    // data-label={translate("Wallet_Online", "Pri_Evo")}
                    className="md-w-25 p-2 sm-hidden"
                >
                    <div className="block md-right-table">
                        <div className="text-medium text-sm ">
                            $
                            {UtilsService.formatAmount(
                                wallet.current_price ?? 0,
                                after_comma,
                            )}
                        </div>
                        {price_change_percentage_24 !== 0 ? (
                            price_change_percentage_24 < 0 ? (
                                <div className="text-red-500 text-sm flex items-center">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            fill="currentColor"
                                            className="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            {" "}
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />{" "}
                                        </svg>
                                    </div>
                                    <div className="text-xs">
                                        {UtilsService.formatDecimal(
                                            UtilsService.getAbsoluteValue(
                                                price_change_percentage_24,
                                            ),
                                            2,
                                        )}{" "}
                                        %
                                    </div>
                                </div>
                            ) : (
                                <div className="text-green-600 text-sm flex items-center">
                                    <div className="rotate-180">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            fill="currentColor"
                                            className="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            {" "}
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />{" "}
                                        </svg>
                                    </div>
                                    <div className="text-xs">
                                        {UtilsService.formatDecimal(
                                            UtilsService.getAbsoluteValue(
                                                price_change_percentage_24,
                                            ),
                                            2,
                                        )}{" "}
                                        %
                                    </div>
                                </div>
                            )
                        ) : (
                            " "
                        )}
                    </div>
                </td>
                <td
                    // data-label={translate("Wallet_Online", "Investi")}
                    className="md-w-25 p-2 sm-hidden"
                >
                    <div className="block md-right-table">
                        <div className="text-medium text-sm">
                            {UtilsService.formatDecimal(
                                wallet.wallet.staking_solde,
                                6,
                            )}{" "}
                            {wallet.abbreviation}
                        </div>
                        <div className="text-slate-400 text-xs">
                            {convertAmountWithCurrency(
                                wallet.wallet.staking_solde *
                                    wallet.current_price,
                            )}
                        </div>
                    </div>
                </td>
                {handleActiveAsset && (
                    <td className="md-w-25 p-2 sm-hidden">
                        <div className="md-flex items-center md-text-c w-full">
                            <div className="m-1">
                                <button
                                    className={links.deposit.class}
                                    onClick={() => {
                                        setCurrentAsset(wallet);
                                        navigate(links.deposit.link);
                                    }}
                                >
                                    {translate("Wallet_Online", "Recharge")}
                                </button>
                            </div>
                            <div className="m-1">
                                {wallet.buy_enabled ? (
                                    <NavLink
                                        to={links.buy.link}
                                        className={links.buy.class}
                                        onClick={() => {
                                            setCurrentAsset(wallet);
                                            navigate(links.buy.link);
                                        }}
                                    >
                                        {translate("ASSET_ACTION", "BUY")}
                                    </NavLink>
                                ) : (
                                    <KpButton
                                        onClick={() => {
                                            setCurrentAsset(wallet);
                                        }}
                                        disabled
                                    >
                                        {translate("ASSET_ACTION", "BUY")}
                                    </KpButton>
                                )}
                            </div>
                            <div className="m-1">
                                {wallet.buy_enabled ? (
                                    <NavLink
                                        to={links.sell.link}
                                        className={links.sell.class}
                                        onClick={() => {
                                            setCurrentAsset(wallet);
                                            navigate(links.sell.link);
                                        }}
                                    >
                                        {translate("ASSET_ACTION", "SELL")}
                                    </NavLink>
                                ) : (
                                    <KpButton
                                        onClick={() => {
                                            setCurrentAsset(wallet);
                                        }}
                                        disabled
                                    >
                                        {translate("ASSET_ACTION", "SELL")}
                                    </KpButton>
                                )}
                            </div>
                            <div className="m-1">
                                <button
                                    className={links.exchange.class}
                                    onClick={() => {
                                        setCurrentAsset(wallet);
                                        navigate(links.exchange.link);
                                    }}
                                >
                                    {translate("ASSET_ACTION", "EXCHANGE")}
                                </button>
                            </div>
                            <div className="mx-1">
                                <button
                                    className={links.transfert.class}
                                    onClick={() => {
                                        setCurrentAsset(wallet);
                                        navigate(links.transfert.link);
                                    }}
                                >
                                    {translate("ASSET_ACTION", "TRANSFERT")}
                                </button>
                            </div>
                        </div>
                    </td>
                )}
            </tr>
            <tr className="simple-hidden">
                {showDetails && (
                    <td
                        className="md-w-25 p-2 !text-slate-500 active"
                        colSpan={4}
                    >
                        {wallet.type === "crypto" && (
                            <>
                                <>
                                    <div className="float-left ml-[48px] mt-2">
                                        Earning
                                    </div>
                                    <div className="block md-right-table">
                                        <div className="text-medium text-sm text-right">
                                            {UtilsService.formatDecimal(
                                                wallet.wallet.staking_solde,
                                                6,
                                            )}{" "}
                                        </div>
                                        <div className="text-slate-400 text-xs text-right">
                                            $
                                            {UtilsService.formatAmount(
                                                wallet.wallet.staking_solde *
                                                    wallet.current_price,
                                                2,
                                            )}
                                        </div>
                                    </div>
                                </>
                            </>
                        )}
                    </td>
                )}
            </tr>
        </>
    );
};

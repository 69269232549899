import React from "react";

interface InputFieldProps {
    id: string;
    label: string;
    type?: string;
    placeholder: string;
    rows?: number;
    value: string;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
}

const InputField: React.FC<InputFieldProps> = ({
    id,
    label,
    type = "text",
    placeholder,
    rows,
    onChange,
    value,
}) => {
    return (
        <div className="mb-4">
            <label
                htmlFor={id}
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            {type === "textarea" ? (
                <textarea
                    id={id}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm !ring-transparent !ring-0 !outline-none focus:!border-kalypay-100"
                    rows={rows}
                    required
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                />
            ) : (
                <input
                    required
                    type={type}
                    id={id}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm !ring-transparent !ring-0 !outline-none focus:!border-kalypay-100 h-[50px]"
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                />
            )}
        </div>
    );
};

export default InputField;

import React from "react";

const CercleIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="512"
            height="512"
            {...props}
        >
            <g
                fill="None"
                fill-opacity="0.0"
                stroke="#000000"
                stroke-opacity="0.00"
                stroke-width="0.3"
            >
                <path
                    d="
      M 0.00 512.00 
      L 512.00 512.00
      L 512.00 0.00
      L 0.00 0.00
      L 0.00 512.00
      M 116.00 424.00 
      C 157.99 456.99 216.49 474.79 271.00 470.00 C 325.51 465.21 371.65 443.22 409.08 405.08 C 446.51 366.95 467.29 318.48 470.78 263.78 C 474.27 209.08 452.48 152.64 419.25 112.75 C 386.01 72.86 341.71 49.33 289.93 39.07 C 238.15 28.82 173.97 43.15 131.97 71.97 C 89.97 100.78 62.33 139.71 45.00 186.00
      C 51.22 152.43 68.38 120.91 91.25 93.25 C 114.12 65.59 140.33 47.36 173.03 31.03 C 205.72 14.70 253.51 5.78 292.00 9.00 C 330.49 12.22 368.40 25.12 399.75 45.25 C 431.09 65.39 451.72 84.41 472.25 115.75 C 492.79 147.09 507.21 182.92 510.00 222.00 C 512.79 261.08 508.06 312.31 490.68 345.68 C 473.29 379.05 456.69 403.61 428.75 427.75 C 400.82 451.90 370.85 466.08 334.78 476.78 C 298.71 487.48 244.03 487.54 207.99 476.01 C 171.95 464.48 142.92 449.80 116.03 424.97
      M 42.00 196.00 
      C 45.14 199.80 38.61 198.76 42.00 196.00 Z"
                />
            </g>
            <g
                fill="None"
                fill-opacity="0.0"
                stroke="#CF8E17"
                stroke-opacity="0.89"
                stroke-width="0.3"
            >
                <path
                    d="
      M 116.03 424.97 
      C 142.92 449.80 171.95 464.48 207.99 476.01 C 244.03 487.54 298.71 487.48 334.78 476.78 C 370.85 466.08 400.82 451.90 428.75 427.75 C 456.69 403.61 473.29 379.05 490.68 345.68 C 508.06 312.31 512.79 261.08 510.00 222.00 C 507.21 182.92 492.79 147.09 472.25 115.75 C 451.72 84.41 431.09 65.39 399.75 45.25 C 368.40 25.12 330.49 12.22 292.00 9.00 C 253.51 5.78 205.72 14.70 173.03 31.03 C 140.33 47.36 114.12 65.59 91.25 93.25 C 68.38 120.91 51.22 152.43 45.00 186.00
      C 62.33 139.71 89.97 100.78 131.97 71.97 C 173.97 43.15 238.15 28.82 289.93 39.07 C 341.71 49.33 386.01 72.86 419.25 112.75 C 452.48 152.64 474.27 209.08 470.78 263.78 C 467.29 318.48 446.51 366.95 409.08 405.08 C 371.65 443.22 325.51 465.21 271.00 470.00 C 216.49 474.79 157.99 456.99 116.00 424.00 Z"
                />
            </g>
            <g
                fill="None"
                fill-opacity="0.0"
                stroke="#CF8E17"
                stroke-opacity="0.08"
                stroke-width="0.3"
            >
                <path
                    d="
      M 42.00 196.00 
      C 38.61 198.76 45.14 199.80 42.00 196.00 Z"
                />
            </g>
            <g fill="#000000" fill-opacity="0.00" stroke="None">
                <path
                    d="
      M 0.00 512.00 
      L 512.00 512.00
      L 512.00 0.00
      L 0.00 0.00
      L 0.00 512.00
      M 116.00 424.00 
      C 157.99 456.99 216.49 474.79 271.00 470.00 C 325.51 465.21 371.65 443.22 409.08 405.08 C 446.51 366.95 467.29 318.48 470.78 263.78 C 474.27 209.08 452.48 152.64 419.25 112.75 C 386.01 72.86 341.71 49.33 289.93 39.07 C 238.15 28.82 173.97 43.15 131.97 71.97 C 89.97 100.78 62.33 139.71 45.00 186.00
      C 51.22 152.43 68.38 120.91 91.25 93.25 C 114.12 65.59 140.33 47.36 173.03 31.03 C 205.72 14.70 253.51 5.78 292.00 9.00 C 330.49 12.22 368.40 25.12 399.75 45.25 C 431.09 65.39 451.72 84.41 472.25 115.75 C 492.79 147.09 507.21 182.92 510.00 222.00 C 512.79 261.08 508.06 312.31 490.68 345.68 C 473.29 379.05 456.69 403.61 428.75 427.75 C 400.82 451.90 370.85 466.08 334.78 476.78 C 298.71 487.48 244.03 487.54 207.99 476.01 C 171.95 464.48 142.92 449.80 116.03 424.97
      M 42.00 196.00 
      C 45.14 199.80 38.61 198.76 42.00 196.00 Z"
                />
            </g>
            <g fill="#CF8E17" fill-opacity="0.89" stroke="None">
                <path
                    d="
      M 116.03 424.97 
      C 142.92 449.80 171.95 464.48 207.99 476.01 C 244.03 487.54 298.71 487.48 334.78 476.78 C 370.85 466.08 400.82 451.90 428.75 427.75 C 456.69 403.61 473.29 379.05 490.68 345.68 C 508.06 312.31 512.79 261.08 510.00 222.00 C 507.21 182.92 492.79 147.09 472.25 115.75 C 451.72 84.41 431.09 65.39 399.75 45.25 C 368.40 25.12 330.49 12.22 292.00 9.00 C 253.51 5.78 205.72 14.70 173.03 31.03 C 140.33 47.36 114.12 65.59 91.25 93.25 C 68.38 120.91 51.22 152.43 45.00 186.00
      C 62.33 139.71 89.97 100.78 131.97 71.97 C 173.97 43.15 238.15 28.82 289.93 39.07 C 341.71 49.33 386.01 72.86 419.25 112.75 C 452.48 152.64 474.27 209.08 470.78 263.78 C 467.29 318.48 446.51 366.95 409.08 405.08 C 371.65 443.22 325.51 465.21 271.00 470.00 C 216.49 474.79 157.99 456.99 116.00 424.00 Z"
                />
            </g>
            <g fill="#CF8E17" fill-opacity="0.08" stroke="None">
                <path
                    d="
      M 42.00 196.00 
      C 38.61 198.76 45.14 199.80 42.00 196.00 Z"
                />
            </g>
        </svg>
    );
};

export default CercleIcon;

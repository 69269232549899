import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import en from "../../translation/translate.en.json";
import fr from "../../translation/translate.fr.json";
import es from "../../translation/translate.es.json";
import tr from "../../translation/translate.tr.json";
import zh from "../../translation/translate.zh.json";
import br from "../../translation/translate.br.json";
import de from "../../translation/translate.de.json";

export enum Language {
    FR = "fr",
    EN = "en",
    DE = "de",
    TR = "tr",
    ZH = "zh",
    ES = "es",
    PT_BRA = "br",
}

export interface AppLanguage {
    language: Language;
    translations: any;
    defaultTranslations: any;
}

const localLanguage = localStorage.getItem("AppLanguage") as Language;
const language = localLanguage ? localLanguage : Language.EN;

const getTranslations = (lang: Language) => {
    switch (lang) {
        case Language.FR:
            return fr;
        case Language.EN:
            return en;

        case Language.ES:
            return es;
        case Language.DE:
            return de;
        case Language.TR:
            return tr;
        case Language.ZH:
            return zh;
        case Language.PT_BRA:
            return br;

        default:
            return en;
    }
};
const translations = getTranslations(language);

const initialState: AppLanguage = {
    language: language,
    translations: translations,
    defaultTranslations: en,
};

const LanguageSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        ChangeAppLanguage(
            state,
            action: PayloadAction<{ language: Language }>,
        ) {
            const language = action.payload.language;
            localStorage.setItem("AppLanguage", language);
            state.language = language;
            state.translations = getTranslations(language);
        },
    },
});

export const { ChangeAppLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;

import "animate.css";
import React, { useState } from "react";
import { useAppDispatch } from "../../../globalState/hook";
import translator from "../../../Components/Extra/Translation//Translate";
import {
    ChangeAppLanguage,
    Language,
} from "../../../globalState/reducers/Language.reducer";
import useLanguage from "../../../Services/Hooks/useLanguage";
import UtilsService from "../../../Services/UtilsService";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const LanguageSwitcher = () => {
    const dispatch = useAppDispatch();
    const [show, setShow] = useState<boolean>(false);
    const { language } = useLanguage();
    const { translate } = translator();

    return (
        <div>
            <button
                type="button"
                className="bg-transparent border-none text-white use-tooltip-language"
                onClick={() => {
                    setShow(!show);
                }}
            >
                {language.toUpperCase()}
            </button>
            <Tooltip
                anchorSelect=".use-tooltip-language"
                // afterHide="animate__animated animate__fadeOutTopRight"
                place="bottom"
                events={["click"]}
                className="animate__animated animate__backInDown"
                style={{
                    zIndex: "1000000000000000000",
                    backgroundColor: "transparent",
                    height: "auto",
                    position: "relative",
                    // boxShadow: "0px 3px 20px #00000030",
                    width: "170px",
                }}
            >
                <ul className="bg-primary text-white p-2 rounded">
                    <li>
                        <button
                            onClick={() =>
                                dispatch(
                                    ChangeAppLanguage({
                                        language: Language.EN,
                                    }),
                                )
                            }
                            className="font-medium flex hover:text-[#daa520]"
                        >
                            <img
                                alt="User Dropdown"
                                className="rounded-full w-6 h-6 object-fill mr-2"
                                src={UtilsService.getPulicImage(
                                    "/dist/image/language/united-states.png",
                                )}
                            />{" "}
                            {translate("Head", "English")} (EN)
                        </button>
                    </li>
                    <li className="my-1">
                        <hr className="dropdown-divider border-white/[0.08]" />
                    </li>
                    <li>
                        <button
                            onClick={() =>
                                dispatch(
                                    ChangeAppLanguage({
                                        language: Language.FR,
                                    }),
                                )
                            }
                            className="font-medium flex hover:text-[#daa520]"
                        >
                            <img
                                alt="User Dropdown"
                                className="rounded-full w-6 h-6 object-fill mr-2"
                                src={UtilsService.getPulicImage(
                                    "/dist/image/language/france.png",
                                )}
                            />{" "}
                            {translate("Head", "French")} (FR)
                        </button>
                    </li>
                    <li className="my-1">
                        <hr className="dropdown-divider border-white/[0.08]" />
                    </li>
                    <li>
                        <button
                            onClick={() =>
                                dispatch(
                                    ChangeAppLanguage({
                                        language: Language.ES,
                                    }),
                                )
                            }
                            className="font-medium flex hover:text-[#daa520]"
                        >
                            <img
                                alt="User Dropdown"
                                className="rounded-full w-6 h-6 object-fill mr-2"
                                src={UtilsService.getPulicImage(
                                    "/dist/image/language/espagne.png",
                                )}
                            />{" "}
                            {translate("Head", "Spain")} (ES)
                        </button>
                    </li>
                </ul>
            </Tooltip>
        </div>
    );
};

export default LanguageSwitcher;

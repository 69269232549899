import axiosApiInstance from "../AxiosInstance";
import UtilsService from "../UtilsService";

export interface SupportDto {
    name: string;
    message: string;
}

export type SupportMailDto = SupportDto & {
    email: string;
};

export type SupportTelegramDto = SupportDto & {
    phone: string;
};

export const SendMailSupport = async (dto: SupportMailDto): Promise<string> => {
    return axiosApiInstance
        .post("/support/mail", dto)
        .then(() => {
            return Promise.resolve("Envoyé avec succès");
        })
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

export const SendTelegramSupport = async (dto: SupportTelegramDto) => {
    return axiosApiInstance
        .post("/support/telegram", dto)
        .then(() => {
            return Promise.resolve("Envoyé avec succès");
        })
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
